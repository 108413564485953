// src/router/index.js
import { createRouter, createWebHashHistory } from 'vue-router';
import GanttChart from '../components/GanttChart.vue';

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    { path: '/', redirect: '/gantt-chart' },
    { path: '/gantt-chart', name: 'GanttChart', component: GanttChart }
  ]
});

export default router;
