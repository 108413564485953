<template>
  <div>
    <input type="datetime-local" v-model="callStartTime" @change="loadData" />
    <div id="gantt-chart" style="width: 100%; height: 800px"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import axios from "axios";

export default {
  name: "GanttChart",
  data() {
    const now = new Date(); // 获取当前日期和时间
    now.setHours(8); // 设置小时为早上8点
    now.setMinutes(0); // 设置分钟为0
    now.setSeconds(0); // 设置秒为0
    now.setMilliseconds(0); // 设置毫秒为0

    // 将时间转换为 ISO 字符串，然后截取前16个字符以符合 datetime-local 的输入格式
    // const callStartTime = now.toISOString().substring(0, 16);
    let localISOTime = `${now.getFullYear()}-${(now.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${now.getDate().toString().padStart(2, "0")}T${now
      .getHours()
      .toString()
      .padStart(2, "0")}:${now.getMinutes().toString().padStart(2, "0")}`;
    console.log(localISOTime);
    return {
      data: [],
      callStartTime: localISOTime, // 初始化为当前时间，格式为YYYY-MM-DDTHH:mm
    };
  },
  mounted() {
    this.loadData(); // 首次加载数据
  },
  methods: {
    loadData() {
      // 使用encodeURIComponent确保URL的时间参数格式正确
      const startTimeParam = encodeURIComponent(this.callStartTime);
      // const url = `http://127.0.0.1:5000/records?call_start_time=${startTimeParam}`;
      const url = `https://report.inshow.co.id/records?call_start_time=${startTimeParam}`;
      axios
        .get(url, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        })
        .then((response) => {
          this.data = response.data;
          this.initChart();
        })
        .catch((error) => {
          console.error("Error loading data:", error);
        });
    },

    initChart() {
      const chartDom = document.getElementById("gantt-chart");
      const myChart = echarts.init(chartDom);
      const option = this.getChartOption();
      myChart.setOption(option);
    },
    getChartOption() {
      const seriesData = this.data
        .map((item) => [
          {
            name: "Wait",
            value: [
              item.agent_name,
              new Date(item.call_wait_duration_start_time).getTime(),
              new Date(item.call_wait_duration_end_time).getTime(),
              "Wait",
            ],
          },
          {
            name: "Talk",
            value: [
              item.agent_name,
              new Date(item.call_talk_start_time).getTime(),
              new Date(item.call_talk_end_time).getTime(),
              "Talk",
            ],
          },
        ])
        .flat();

      const waitData = seriesData.filter((item) => item.name === "Wait");
      const talkData = seriesData.filter((item) => item.name === "Talk");

      return {
        tooltip: {
          formatter: function (params) {
            const startTime = new Date(params.value[1]).toLocaleTimeString();
            const endTime = new Date(params.value[2]).toLocaleTimeString();
            return `${params.marker} ${params.name}: ${startTime} ~ ${endTime}`;
          },
        },
        legend: {
          data: [
            { name: "Wait", icon: "roundRect", itemStyle: { color: "blue" } },
            { name: "Talk", icon: "roundRect", itemStyle: { color: "red" } },
          ],
        },
        grid: {
          left: "10%",
          right: "10%",
          bottom: "10%",
          containLabel: true,
        },
        xAxis: {
          type: "time",
          axisLabel: {
            formatter: function (value) {
              return new Date(value).toLocaleTimeString();
            },
          },
          boundaryGap: false,
          min: function (value) {
            return value.min - 60 * 1000; // Adding 1 minute padding
          },
          max: function (value) {
            return value.max + 60 * 1000; // Adding 1 minute padding
          },
        },
        yAxis: {
          type: "category",
          data: Array.from(new Set(this.data.map((item) => item.agent_name))),
          boundaryGap: true,
          containLabel: true,
        },
        dataZoom: [
          {
            type: "inside",
            xAxisIndex: [0],
            filterMode: "filter",
            start: 0,
            end: 100,
          },
          {
            type: "slider",
            xAxisIndex: [0],
            filterMode: "filter",
            start: 0,
            end: 100,
          },
        ],
        series: [
          {
            name: "Wait",
            type: "custom",
            renderItem: this.renderItem.bind(this, "blue"),
            encode: {
              x: [1, 2],
              y: 0,
            },
            data: waitData,
          },
          {
            name: "Talk",
            type: "custom",
            renderItem: this.renderItem.bind(this, "red"),
            encode: {
              x: [1, 2],
              y: 0,
            },
            data: talkData,
          },
        ],
      };
    },
    renderItem(color, params, api) {
      const categoryIndex = api.value(0);
      const start = api.coord([api.value(1), categoryIndex]);
      const end = api.coord([api.value(2), categoryIndex]);
      const height = api.size([0, 1])[1] * 0.6;

      return {
        type: "rect",
        shape: {
          x: start[0],
          y: start[1] - height / 2,
          width: end[0] - start[0],
          height: height,
        },
        style: api.style({
          fill: color,
        }),
      };
    },
  },
};
</script>

<style scoped>
#gantt-chart {
  width: 100%;
  height: 800px;
}
</style>
